import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"


export default ({data}) => {
  const project = data.projectJson
  console.log(project)

  return (
    <Layout>
      <div className="project-intro">
        <h1>{project.title}</h1>
        <div className="project-intro-content">
          <div className="description">{project.description}</div>
          <div className="body">{project.body}</div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    projectJson(fields: { slug: { eq: $slug } }) {
      title
      body
    }
  }
`